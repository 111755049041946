import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore } from '@/store';
let AdesaoStepContaAprovada = class AdesaoStepContaAprovada extends Vue {
    constructor() {
        super(...arguments);
        this.items = [
            {
                title: 'Cobrança automática',
                subtitle: `O aluno recebe no seu whatsapp a cobrança`,
                icon: 'check'
            },
            {
                title: 'Pagamento pelo Portal do aluno',
                subtitle: 'Além do PIX os seus alunos poderão pagar com o cartão de crédito pelo portal do aluno',
                icon: 'check'
            },
            {
                title: 'Independente de instituição bancária',
                subtitle: `Agora não importa mais o banco que você utiliza, com DrivePay você vai poder ativar a integração com qualquer banco`,
                icon: 'check'
            },
            {
                title: 'Rapido',
                subtitle: `Em poucas horas você já está habilitado a receber pelo DrivePay`,
                icon: 'check'
            },
            {
                title: 'Pagamento recorrente (Em Breve)',
                subtitle: `Faça a cobrança de forma semelhante ao Netflix`,
                icon: 'check'
            }
        ];
        this.items2 = [
            {
                title: 'Recebimento por PIX',
                subtitle: 'R$0,89 por pix recebido, você só paga se o aluno pagar você'
            },
            {
                title: 'Ativação e setup',
                subtitle: `Custo zero para ativação`
            },
            {
                title: 'Cartão de crédito',
                subtitle: `1,49% + R$0,29 à vista<br/>`
            }
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
};
AdesaoStepContaAprovada = __decorate([
    Component({
        components: {}
    })
], AdesaoStepContaAprovada);
export default AdesaoStepContaAprovada;
