import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { asaasStore, mainStore } from '@/store';
let AdesaoStepConfirmarAdesao = class AdesaoStepConfirmarAdesao extends Vue {
    constructor() {
        super(...arguments);
        this.companyTypeOptions = [
            { text: 'Limitada', value: 'LIMITED' },
            { text: 'Individual', value: 'INDIVIDUAL' }
        ];
        this.incomeValue = null;
        this.companyType = null;
        this.email = null;
        this.celular = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async criarConta() {
        this.$swal({
            title: 'Confirmação de Email e número de celular',
            html: `
      <div style="text-align: justify;"><span style="justify-content: left">Antes de prosseguir com a criação de uma conta é importante garantir que o <b style="color: red">email e celular</b>,
      informados estejam corretos, pois você receberá no email informado um link para completar o cadastro dentro da plataforma do ASAAS e o número de celular irá receber o token para fazer o seu login dentro do portal
      <p style="margin-top: 10px; font-size: 0.9rem;">
        <b>OBS:</b> Se o email e/ou celular estiverem errados, entre em contato com o suporte do Drive pelo 
        <a href="${this.getLinkWhatsappWeb()}" target="_blank" style="color: #25D366; text-decoration: none;">
          whatapp </a> para fazer a atualização.
      </p>
      </span></div>
      `,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await asaasStore.subcontaCriar({
                    incomeValue: this.incomeValue,
                    companyType: this.companyType
                });
                window.location.reload();
            }
        });
    }
    created() {
        this.email = this.userProfile.email;
        this.celular = this.userProfile.company.responsavel_financeiro_celular;
    }
};
AdesaoStepConfirmarAdesao = __decorate([
    Component({
        components: {}
    })
], AdesaoStepConfirmarAdesao);
export default AdesaoStepConfirmarAdesao;
